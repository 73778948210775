import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 999;
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

export const Content = styled.div`
  flex: 8;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  padding-bottom: 10px;
`

export const Header = styled.div`
  display: flex;
  align-self: start;
  align-items: center;
  height: 56px;
  width: 100%;
  background: #0a0d36;
  border-radius: 8px 8px 0 0;
  color: #fff;
  padding: 16px;
  justify-content: space-between;

  img {
    cursor: pointer;
  }
`

export const ChatList = styled.div`
  width: 100%;
  height: 100%;
  align-self: center;
  padding: 0 20px 20px 20px;
  max-height: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    display: flex;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(96, 111, 126);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`

export const MessageBox = styled.div`
  display: flex;
  height: 40px;
  width: 100%;
  align-self: end;
  padding: 20px;
  align-items: center;
  justify-content: space-evenly;
  gap: 8px;

  textarea {
    flex: 10;
    box-shadow: none;
    resize: none;
    outline: 0;
    height: 40px;
    min-height: 40px !important;
  }

  button {
    flex: 1;
  }
`

export const Setting = styled.div`
  width: 60%;
  height: 45%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 0 6px 16px rgba(10, 13, 54, 0.08);

  .title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
  }

  .checkbox {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 12px;
  }

  .timeout {
    width: 65%;
    margin-bottom: 12px;
  }
`
