import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { handleUpdateBotData } from 'store/actions'
import { StyledTextInput } from '../Bubbles.styled'
import { getBotDataByName } from 'utils'

export const TextInput = ({ id }) => {
  const dispatch = useDispatch()

  const onChange = (event) => {
    const { value, name } = event.target

    dispatch(
      handleUpdateBotData(id, {
        [name]: value
      })
    )
  }

  return (
    <StyledTextInput
      value={getBotDataByName(id, 'text')}
      name='text'
      placeholder='Hello, welcome to Connexease Chat Assisstant. Please write your information.'
      onChange={onChange}
    />
  )
}

TextInput.propTypes = {
  id: PropTypes.string
}
