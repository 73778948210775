import styled from 'styled-components'
import { Button } from 'antd'

import { Input } from 'foundation'

export const ConditionButtonWrapper = styled.div`
  margin-top: 8px;
  border: 1px solid var(--bg-light-1);
  border-radius: 4px;
  background-color: var(--bg-light-3);
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-dark-3);
  }

  .react-icons {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    color: var(--text-dark-3);
  }
`

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  width: 296px;
  position: absolute;
  left: ${(p) => p.left + 75}px;
  top: ${(p) => p.top + 40}px;
  background: #fafafc;
  border: 1px solid #e3e4eb;
  box-shadow: 0px 2px 6px rgba(8, 10, 62, 0.1);
  border-radius: 8px;
  z-index: 1;

  .ant-divider-horizontal {
    margin: inherit;
  }
`

export const Head = styled.div`
  display: flex;
  width: 100%;
  padding: 12px;
  gap: 10.5px;
  justify-content: space-between;
`

export const ConditionIcon = styled.div`
  display: flex;
  align-items: center;
  order: 0;

  .react-icons {
    width: 22px;
    height: 22px;
    color: var(--primary);
  }
`

export const ConditionTitle = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary);
  order: 1;
`

export const CloseIcon = styled.div`
  display: flex;
  align-items: center;
  order: 2;
  cursor: pointer;

  .react-icons {
    width: 25px;
    height: 25px;
    color: var(--text-dark-4);
  }
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
`

export const SectionTitle = styled.span`
  display: block;
  margin-bottom: 4px;
`

export const StyledConditionTextInput = styled(Input)`
  padding: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-dark-2);

  input {
    color: var(--text-dark-2);
  }
`

export const ConditionButton = styled(Button)`
  background-color: var(--secondary);
  color: #fff;

  .react-icons {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
`
