import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper } from './Input.styled'

const Input = ({ ...props }) => {
  return <Wrapper {...props} />
}

Input.propTypes = {
  search: PropTypes.bool
}

Input.Group = Wrapper.Group
Input.TextArea = Wrapper.TextArea
Input.Search = Wrapper.Search
Input.Password = Wrapper.Password

export { Input }
