import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { MdOutlineDelete } from 'react-icons/md'

import { Dropdown } from 'foundation'
import { handleUpdateInputs } from 'store/actions'

import { types } from './constants'
import { Wrapper, TypeBadge, Icon, Text, Input } from './Inputs.styled'

export const Inputs = ({ id, inputType }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(handleUpdateInputs(id, { type: inputType }))
  }, [id, inputType])

  return (
    <Dropdown
      trigger={['contextMenu']}
      menu={{
        items: [
          {
            key: 'remove',
            label: 'Remove Input',
            icon: <MdOutlineDelete size={20} />,
            danger: true,
            onClick: () => dispatch(handleUpdateInputs(id, {}))
          }
        ]
      }}
    >
      <Wrapper>
        <TypeBadge>
          <Icon>{types[inputType].icon}</Icon>
          <Text>{types[inputType].display}</Text>
        </TypeBadge>

        <Input
          disabled
          type={types[inputType].type}
          placeholder={`${types[inputType].display} input...`}
        />
      </Wrapper>
    </Dropdown>
  )
}

Inputs.propTypes = {
  id: PropTypes.string,
  inputType: PropTypes.string
}

Inputs.displayName = 'Inputs'
