import { TextBubble, MenuTextBubble, MenuBubble } from 'components/Bubbles'
import { CustomEdge } from 'components/Edges'

export const nodeTypes = {
  textNode: TextBubble,
  menuNode: MenuBubble,
  menuTextNode: MenuTextBubble
}

export const edgeTypes = {
  customEdge: CustomEdge
}
