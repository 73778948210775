import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  clear: both;
  max-width: 60%;
  margin-top: 10px;
  word-break: normal;
  white-space: pre-wrap;
  box-shadow: 0 34px 44px rgba(199, 211, 230, 0.2);
  background-attachment: fixed;
  position: relative;
  border-radius: 8px;
  background: #f2f2f5;

  .message {
    padding: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  ${(p) => {
    return p.isSystem
      ? `
      color: #555770;
      float: left;
      margin-left: 16px;
      background: #EBEBF0;
    `
      : `
      float: right;
      margin-right: 16px;
      background: #3860EE;
      color: #ffffff;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
`
  }}

  ${(p) => {
    return p.isAction
      ? `
      background: #ffe2b4;
      box-shadow: 0 15px 20px rgb(171 204 255 / 20%);
      border-radius: 4px;
      max-width: 70%;
    `
      : ''
  }}
`

export const InteractiveItems = styled.div`
  width: 100%;
  display: inline-block;
  padding: 16px;
  background-color: #f2f2f5;

  div,
  a {
    padding: 10px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    background-color: #fff;
    border-radius: 4px;
    color: #434dda;
    margin-bottom: 8px;
    display: block;
    text-decoration: none !important;
    cursor: pointer;
  }

  .menu-button {
    cursor: pointer;

    &:hover {
      background-color: #e3e6ed;
    }
  }
  .menu-button img {
    vertical-align: baseline;
  }
  .menu-button span,
  .button span {
    margin-left: 5px;
  }

  .selected {
    background-color: #e3e6ed;
    color: #000;
  }
`

export const InteractiveModalItems = styled.div`
  padding: 10px 10px 5px 10px;

  .button {
    text-align: center;
    margin-top: 17px;

    button {
      padding: 13px 60px 27px 60px !important;
    }
  }

  .item-row {
    padding: 8px;
    background: #fff;
    border: 1px solid #e4e6ed;
    box-sizing: border-box;
    margin-bottom: 8px;
    display: flex;

    input[type='radio'][disabled],
    input[type='checkbox'][disabled],
    input[type='radio'].disabled,
    input[type='checkbox'].disabled {
      cursor: default !important;
    }
  }

  .item-radio {
    align-items: center;
    display: flex;
    color: #0a0d36;
  }

  .item-row .ant-radio {
    cursor: default !important;
  }

  .item-row .ant-radio-wrapper {
    margin-right: 0;
    cursor: default;
  }

  .item-row .ant-radio-disabled .ant-radio-inner {
    border-color: #0a0d36 !important;
    background-color: #fff !important;
    width: 20px;
    height: 20px;
  }

  .item-row .ant-radio-disabled + span {
    color: rgba(0, 0, 0, 0.25);
    cursor: default !important;
  }

  .item-row .span.ant-radio + * {
    padding-left: 8px;
    padding-right: 0;
  }

  .item-row .ant-radio-disabled .ant-radio-inner:after {
    background-color: #0a0d36;
    width: 12px;
    height: 12px;
    border-radius: 10px;
  }

  .item-title {
    font-size: 14px;
    color: #777a89;
  }

  .item-selected {
    color: #0a0d36;
  }

  .item-description {
    font-size: 11px;
    color: #777a8987;
    padding-left: 2px;
  }
`
