import { actionTypes } from '../actionTypes'

export const initialElements = (payload) => (dispatch) => {
  return dispatch({
    type: actionTypes.INITIAL_ELEMENTS,
    payload
  })
}

export const handleInstance = (instance) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_INSTANCE,
    instance
  })
}

export const handleViewport = (viewport) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_VIEWPORT,
    viewport
  })
}

export const handleAddNode = (node) => (dispatch) => {
  return dispatch({
    type: actionTypes.ADD_NODE,
    node
  })
}

export const handleDeleteNode = (nodeId) => (dispatch) => {
  return dispatch({
    type: actionTypes.DELETE_NODE,
    nodeId
  })
}

export const handleUpdateNodeName = (nodeId, name) => (dispatch) => {
  return dispatch({
    type: actionTypes.UPDATE_NODE_NAME,
    nodeId,
    name
  })
}

export const handleUpdateData = (nodeId, data, key) => (dispatch) => {
  return dispatch({
    type: actionTypes.UPDATE_NODE_DATA,
    nodeId,
    data,
    key
  })
}

export const handleUpdateBotData = (nodeId, data) => (dispatch) => {
  return dispatch({
    type: actionTypes.UPDATE_NODE_BOT_DATA,
    nodeId,
    data
  })
}

export const handleUpdateInputs = (nodeId, data) => (dispatch) => {
  return dispatch({
    type: actionTypes.UPDATE_NODE_DATA,
    nodeId,
    data,
    key: 'input'
  })
}

export const handleUpdateCondition = (nodeId, data) => (dispatch) => {
  return dispatch({
    type: actionTypes.UPDATE_NODE_DATA,
    nodeId,
    data,
    key: 'condition'
  })
}

export const handleNodesChange = (changes) => (dispatch) => {
  return dispatch({
    type: actionTypes.HANDLE_CHANGE_NODES,
    changes
  })
}

export const handleEdgesChange = (changes) => (dispatch) => {
  return dispatch({
    type: actionTypes.HANDLE_CHANGE_EDGES,
    changes
  })
}

export const handleConnect = (connection) => (dispatch) => {
  connection = { ...connection, type: 'customEdge' }

  dispatch(handleUpdateData(connection.source, connection.target, 'to'))

  return dispatch({
    type: actionTypes.HANDLE_CONNECT,
    connection
  })
}
