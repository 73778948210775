import React from 'react'
import {
  MdOutlineAlternateEmail,
  MdOutlinePhone,
  MdOutlineTag,
  MdOutlineTextFields
} from 'react-icons/md'

export const types = {
  text: {
    display: 'Text',
    type: 'text',
    icon: <MdOutlineTextFields />
  },
  number: {
    display: 'Number',
    type: 'number',
    icon: <MdOutlineTag />
  },
  email: {
    display: 'Email',
    type: 'email',
    icon: <MdOutlineAlternateEmail />
  },
  phone: {
    display: 'Phone',
    type: 'text',
    icon: <MdOutlinePhone />
  }
}
