import React from 'react'
import { Col, Row } from 'antd'
import {
  MdOutlineAlternateEmail,
  MdOutlineChromeReaderMode,
  MdOutlineForum,
  MdOutlineLock,
  MdOutlineMenuOpen,
  MdOutlinePhone,
  MdOutlineRepeat,
  MdOutlineRule,
  MdOutlineTag,
  MdOutlineTextFields,
  MdOutlineTimelapse
} from 'react-icons/md'

import {
  ActionItem,
  ActionItemList,
  ActionMenuWrapper,
  ActionSection,
  ActionTitle,
  WIPOverlay
} from './ActionMenu.styled'

export const ActionMenu = () => {
  const onDragStart = (event, nodeOptions) => {
    event.dataTransfer.setData(
      'application/reactflow',
      JSON.stringify(nodeOptions)
    )
    event.dataTransfer.effectAllowed = 'move'
  }

  const onInputDragStart = (event) => {
    event.preventDefault()

    return null

    /* event.dataTransfer.setData(
      'text/plain',
      JSON.stringify({
        dataType: 'input',
        data: { type }
      })
    ) */
  }

  const onConditionDragStart = (event) => {
    event.preventDefault()

    return null

    /* event.dataTransfer.setData(
      'text/plain',
      JSON.stringify({
        dataType: 'condition',
        data: { type }
      })
    ) */
  }

  return (
    <ActionMenuWrapper>
      <ActionSection>
        <ActionTitle>Bubbles</ActionTitle>
        <ActionItemList>
          <ActionItem
            onDragStart={(event) => {
              onDragStart(event, {
                type: 'textNode',
                data: {
                  label: 'Your Text Bubble',
                  bot: { text: '' },
                  condition: {},
                  input: {}
                }
              })
            }}
          >
            <MdOutlineForum />
            Text
          </ActionItem>
          <ActionItem
            onDragStart={(event) => {
              onDragStart(event, {
                type: 'menuNode',
                data: {
                  label: 'Your Menu Bubble',
                  bot: { text: '', hasCheck: true },
                  options: [],
                  condition: {},
                  input: {}
                }
              })
            }}
          >
            <MdOutlineMenuOpen />
            Menu
          </ActionItem>
          <ActionItem
            onDragStart={(event) => {
              onDragStart(event, {
                type: 'menuTextNode',
                data: {
                  label: 'Your Menu + Text Bubble',
                  bot: { text: '', hasCheck: true },
                  options: [],
                  condition: {},
                  input: {}
                }
              })
            }}
          >
            <MdOutlineChromeReaderMode />
            Menu + Text
          </ActionItem>
        </ActionItemList>
      </ActionSection>

      <ActionSection>
        <ActionTitle>Inputs</ActionTitle>
        <ActionItemList>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <ActionItem
                onDragStart={(event) => {
                  onInputDragStart(event, 'text')
                }}
              >
                <MdOutlineTextFields />
                Text
              </ActionItem>
            </Col>
            <Col span={12}>
              <ActionItem
                onDragStart={(event) => {
                  onInputDragStart(event, 'number')
                }}
              >
                <MdOutlineTag />
                Number
              </ActionItem>
            </Col>
            <Col span={12}>
              <ActionItem
                onDragStart={(event) => {
                  onInputDragStart(event, 'email')
                }}
              >
                <MdOutlineAlternateEmail />
                Email
              </ActionItem>
            </Col>
            <Col span={12}>
              <ActionItem
                onDragStart={(event) => {
                  onInputDragStart(event, 'phone')
                }}
              >
                <MdOutlinePhone />
                Phone
              </ActionItem>
            </Col>
          </Row>
        </ActionItemList>

        <WIPOverlay>
          <MdOutlineLock />
          <span>Work in Progress</span>
        </WIPOverlay>
      </ActionSection>

      <ActionSection>
        <ActionTitle>Conditions</ActionTitle>
        <ActionItemList>
          <ActionItem
            onDragStart={(event) => {
              onConditionDragStart(event, 'repeat')
            }}
          >
            <MdOutlineRepeat />
            Repeat
          </ActionItem>
          <ActionItem
            onDragStart={(event) => {
              onConditionDragStart(event, 'duration')
            }}
          >
            <MdOutlineTimelapse />
            Duration
          </ActionItem>
          <ActionItem
            onDragStart={(event) => {
              onConditionDragStart(event, 'wrongAnswer')
            }}
          >
            <MdOutlineRule />
            Wrong Selection
          </ActionItem>
        </ActionItemList>

        <WIPOverlay>
          <MdOutlineLock />
          <span>Work in Progress</span>
        </WIPOverlay>
      </ActionSection>
    </ActionMenuWrapper>
  )
}
