import React from 'react'
import {
  MdOutlineRepeat,
  MdOutlineRule,
  MdOutlineTimelapse
} from 'react-icons/md'

export const conditionOptions = [
  {
    value: 'repeat',
    label: 'Repeat'
  },
  {
    value: 'duration',
    label: 'Duration'
  },
  {
    value: 'wrongAnswer',
    label: 'Wrong Answer'
  }
]

export const actionOptions = [
  {
    value: 'setType',
    label: 'Set Conversation Type'
  },
  {
    value: 'assign_to_group',
    label: 'Assign to Agent Group'
  },
  {
    value: 'assign_to_agent',
    label: 'Assign to Agent'
  },
  {
    value: 'archive',
    label: 'Archive Conversation'
  }
]

export const icon = {
  repeat: <MdOutlineRepeat />,
  duration: <MdOutlineTimelapse />,
  wrongAnswer: <MdOutlineRule />
}

export const conditionInitialData = (type = 'repeat') => ({
  type,
  value: 1,
  duration: 1,
  action: {
    type: null,
    agent: null,
    agentGroup: null,
    deleteContent: false,
    text: null
  }
})

export const actionInitialData = {
  action: {
    type: null,
    conversationType: null,
    agent: null,
    agentGroup: null,
    deleteContent: false,
    text: null
  }
}
