import { createMachine, send } from 'xstate'

export const flowMachine = (stages, changeState, sendMessage, setting) => {
  const states = {}
  const guards = {}
  let actions = {}

  const prepareTransitions = () => {
    const stageKeys = Object.keys(stages)

    for (let i = 0; i < stageKeys.length; i += 1) {
      const key = stageKeys[i]
      const stage = stages[key]
      const state = {}
      const meta = {}

      if (stage?.initial === true) {
        changeState(key)
      }

      if (stage?.status) {
        meta.status = stage.status
      }
      if (stage?.check != null) {
        if (stage.check === 'True') {
          state.on = {
            MESSAGE: {
              target: stage.on_true?.to
            }
          }
        } else {
          state.on = {
            MESSAGE: [
              {
                target: stage.on_true?.to,
                cond: newGuard(key, stage, setting)
              },
              { target: stage?.on_false ? stage.on_false?.to : 'init' }
            ]
          }
        }
      }

      if (stage?.ask != null) {
        const askStageKeys = Object.keys(stage)
        const askKeys = Object.keys(stage.ask)
        const askHandlers = []

        if (stage?.actions == null) {
          stage.actions = []
        }

        stage.actions.push({
          to: key,
          type: 'to'
        })

        for (
          let askKeyIndex = 0;
          askKeyIndex < askKeys.length;
          askKeyIndex += 1
        ) {
          const askKey = askKeys[askKeyIndex]
          const condName = `${askKey}_ask_func`
          const condLogic = `return context.message.content == ${stage.ask[askKey]};`
          let target = ''

          guards[condName] = new Function('context', 'event', condLogic)
          for (
            let stageKeyIndex = 0;
            stageKeyIndex < askStageKeys.length;
            stageKeyIndex += 1
          ) {
            const askStageKey = askStageKeys[stageKeyIndex]

            if (askStageKey.length > 3 && askStageKey.slice(3) === askKey) {
              target = stage[askStageKey].to
            }
          }
          askHandlers.push({
            target,
            cond: condName
          })
        }
        askHandlers.push({ target: 'break_flow' })
        state.on = {
          MESSAGE: askHandlers
        }
        if (stage?.ask_text) {
          stage.actions.push({
            text: stage.ask_text,
            type: 'send',
            interactive: stage?.interactive
          })
        }
      }

      if (stage?.actions != null) {
        const stageActions = generateActions(key, stage, meta)

        actions = {
          ...actions,
          ...stageActions
        }
        state.entry = Object.keys(stageActions)
      }
      state.meta = meta
      states[key] = state
    }
    states.break_flow = {}
  }

  const newGuard = (key, stage) => {
    const name = `${key}_check_func`

    guards[name] = checkGuardFunc(stage.check, false)
    return name
  }

  const checkGuardFunc = (checkExpression, negative) => {
    const expression = generateGuardExpression(checkExpression)
    const logic = `const message = context.message; return ${
      negative ? '!' : ''
    }(${expression});`

    return new Function('context', 'event', logic)
  }

  const generateGuardExpression = (expression) => {
    if (
      expression.toLowerCase() === 'false' ||
      expression.toLowerCase() === 'true'
    ) {
      return expression.toLowerCase()
    }
    if (expression.includes('message.content') && expression.includes(' in ')) {
      const expressionArray = expression
        .split(' in ')[1]
        .replace('(', '')
        .replace(')', '')

      return `[${expressionArray}].includes(message.content)`
    }
    if (expression.includes('check_working_hours')) {
      return setting.isWorkingHours.toString()
    }
    if (expression.includes('check_customer_group')) {
      return setting.customerIsInGroup.toString()
    }
    return expression
  }

  const generateActions = (key, stage, meta) => {
    const action = {}
    let requireBreak = true

    for (let i = 0; i < stage.actions.length; i += 1) {
      const actionObj = stage.actions[i]
      const data = {
        interactive: actionObj?.interactive
      }

      switch (actionObj.type) {
        case 'send':
          action[`${key}_send_message_action_${i}`] = () => {
            sendMessage(actionObj.text, data)
          }
          break
        case 'set_type':
          action[`${key}_set_type_action_${i}`] = () => {
            sendMessage(
              `Conversation set type: TypeName: ${actionObj.name} - TypeSub: ${actionObj.sub}`,
              data,
              true,
              true
            )
          }
          break
        case 'archive':
          action[`${key}_archive_action_${i}`] = () => {
            sendMessage(
              `Conversation archived${
                actionObj?.deletecontent === true ? ': Content deleted.' : '.'
              }`,
              data,
              true,
              true
            )
          }
          break
        case 'assign_to_agent':
          action[`${key}_assign_to_agent_action_${i}`] = () => {
            sendMessage(
              `Conversation assigned to ${actionObj.email}`,
              data,
              true,
              true
            )
          }
          break
        case 'assign_to_group':
          action[`${key}_assign_to_group_action_${i}`] = () => {
            sendMessage(
              `Conversation assigned to agent group. AgentGroupUUID: ${actionObj.uuid}`,
              data,
              true,
              true
            )
          }
          break
        case 'to':
          action[`${key}_send_to_action_${i}`] = () => {
            changeState(actionObj.to)
            send(actionObj.to)
          }
          if (stages[actionObj.to]?.timeout) {
            meta.timeout = stages[actionObj.to]?.timeout
          }
          requireBreak = false
          break
        default:
          break
      }
    }
    if (requireBreak) {
      action[`${key}_break_action`] = () => {
        changeState('break_flow')
        send('break_flow')
      }
    }
    return action
  }

  prepareTransitions()

  const toggleMachine = createMachine(
    {
      predictableActionArguments: true,
      id: 'flow',
      initial: 'init',
      context: {
        message: {
          content: ''
        }
      },
      states
    },
    {
      guards,
      actions
    }
  )

  return toggleMachine
}
