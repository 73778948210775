import styled from 'styled-components'
import { Input, Button } from 'foundation'
import { Select } from 'antd'

export const Wrapper = styled.div`
  border-radius: 8px;
  background-color: var(--bg-light-4);
  box-shadow: 0px 2px 6px rgba(8, 10, 62, 0.1);
  padding: 12px;
  max-width: 270px;
  width: 270px;

  .react-flow__handle {
    width: 8px;
    height: 8px;
    background-color: var(--secondary-light);
  }

  &:hover {
    .delete-btn {
      opacity: 1;
    }
  }
`

export const Title = styled.div.attrs({
  className: 'flex items-center justify-between'
})`
  font-size: 1rem;
  font-weight: 500;
  color: var(--primary);
  margin-bottom: 8px;

  .name-input-wrapper {
    margin: 0 8px;
    flex: 1;

    .ant-input {
      padding: 2px;
      font-weight: 500;
      border: 0;
      width: 100%;

      &:focus {
        box-shadow: 0 0 0 2px var(--blue-3);
      }
    }
  }
`

export const OptionSelector = styled(Select).attrs({ className: 'nodrag' })`
  width: 100%;
  margin: 0 8px;

  .ant-select-selector {
    border: 0 !important;
    padding: 0 4px !important;
  }

  .ant-select-arrow {
    display: none;
  }

  .ant-select-selection-item {
    font-weight: 500;
  }
`

export const BubbleIcon = styled.div`
  display: flex;

  .react-icons {
    width: 20px;
    height: 20px;
    color: var(--secondary);
  }
`
export const BubbleDeleteIcon = styled.div.attrs({
  className: 'flex flex-center delete-btn'
})`
  border-radius: 100%;
  padding: 4px;
  opacity: 0;
  transition: opacity 0.25s;
  cursor: pointer;

  .react-icons {
    width: 16px;
    height: 16px;
    color: var(--red-2);
  }
`

export const StyledCaptionInput = styled(Input)`
  padding: 8px;
`

export const StyledTextInput = styled(Input.TextArea).attrs({
  autoSize: { minRows: 2, maxRows: 6 }
})`
  padding: 8px;
`

export const RoundedButton = styled(Button).attrs({
  className: 'flex flex-center'
})`
  background-color: var(--text-light);
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 0;
  padding: 0;

  &:hover {
    background-color: var(--text-light-2);
  }

  .react-icons {
    width: 18px;
    height: 18px;
    color: var(--secondary);
  }
`

export const OptionsWrapper = styled.div`
  margin-top: 8px;
  border: 1px solid var(--bg-light-1);
  border-radius: 4px;
  padding: 12px;
  background-color: var(--bg-light-3);
`

export const OptionWrapper = styled.div`
  position: relative;

  & + & {
    margin-top: 12px;
  }
`

export const NewOptionButton = styled.div.attrs({
  className: 'flex flex-center nodrag'
})`
  margin-top: 12px;
`

export const OptionActionButton = styled(RoundedButton)`
  opacity: 0;
  position: absolute;
  right: 8px;
  z-index: -1;
  top: 50%;
  transform: translate(0, -50%);

  .react-icons {
    width: 16px;
    height: 16px;
    color: var(--text-dark-3);
  }
`

export const OptionButton = styled(Input)`
  padding: 8px;

  &:focus + ${OptionActionButton} {
    z-index: 2;
    opacity: 1;
  }
`

export const DropAreaWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background: var(--bg-light-2);
  border-radius: 8px;
  margin-top: 8px;

  .react-icons {
    width: 20px;
    height: 20px;
    color: var(--text-dark-3);
  }
`

export const ConditionWrapper = styled.div`
  margin-top: 8px;
  border: 1px solid var(--bg-light-1);
  border-radius: 4px;
  background-color: var(--bg-light-3);
  padding: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;

  .react-icons {
    width: 14px;
    height: 14px;
    margin-right: 10px;
    color: var(--text-light);
  }
`
