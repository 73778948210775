import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { TbDragDrop2 } from 'react-icons/tb'
import { Wrapper, DropAreaWrapper } from '../Bubbles.styled'

import { Condition } from 'components/Conditions'
import { Inputs } from 'components/Inputs'

export const DroppableWrapper = ({ id, children }) => {
  const node = useSelector((state) => _.find(state.elements.nodes, ['id', id]))

  const [isDragOver, setIsDragOver] = useState(false)
  const [inputElement, setInputElement] = useState(null)
  const [conditionElement, setConditionElement] = useState(null)

  useEffect(() => {
    if (node?.data) {
      setInputElement(node.data?.input)

      setConditionElement(node.data?.condition)
    }
  }, [node?.data?.input, node?.data?.condition])

  const handleDragOver = (e) => {
    e.preventDefault()

    setIsDragOver(true)
  }

  const handleDrop = (e) => {
    e.preventDefault()

    const transferedData = e.dataTransfer.getData('text/plain')

    if (transferedData) {
      const { dataType, data } = JSON.parse(transferedData)

      if (dataType) {
        if (dataType === 'condition') {
          setConditionElement(data)
        } else if (dataType === 'input') {
          setInputElement(data)
        }
      }
    }

    setIsDragOver(false)
    return null
  }

  return (
    <Wrapper
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={() => setIsDragOver(false)}
    >
      {children || null}

      {isDragOver && (
        <DropAreaWrapper>
          <TbDragDrop2 />
        </DropAreaWrapper>
      )}

      {!_.isEmpty(inputElement) && (
        <Inputs id={id} inputType={inputElement.type} />
      )}

      {!_.isEmpty(conditionElement) && (
        <Condition id={id} conditionType={conditionElement.type} />
      )}
    </Wrapper>
  )
}

DroppableWrapper.Option = Wrapper.Option

DroppableWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}
