import _ from 'lodash'

// WORK IN PROGRESS

const hasAnySelect = (nodeId, nodes, edges) => {
  const targets = _.map(_.filter(edges, ['source', nodeId]), 'target')

  if (targets.length) {
    const options = _.filter(nodes, ({ id }) => targets.includes(id))
    const any = _.find(options, ({ data }) => data.bot?.option?.value === 'any')

    if (any) {
      return {
        has: true,
        both: options.length > 1,
        nodeIds: _.filter(targets, (t) => t !== any.id),
        anyId: any.id
      }
    }
  }

  return { has: false, both: false }
}

// INTERACTIVE
/* const interactiveButtons = (options) => {
  return {
    data: {
      type: 'button',
      action: {
        items: _.chain(options).map((option, key) => {
          return {
            type: 'reply',
            content: {
              id: (key + 1).toString(),
              title: option.value
            }
          }
        })
      },
      footer: {},
      header: {}
    }
  }
} */

// BUBBLES
const bubbles = (nodes) => {
  return _.chain(nodes)
    .keyBy('id')
    .mapValues((node) => {
      const { id, type, data } = node
      const { to, bot, options } = data

      if (type === 'textNode') {
        const actions = [{ text: bot.text, type: 'send' }]

        if (to) {
          actions.push({ to: to, type: 'to' })
        }

        return {
          actions
        }
      } else if (type === 'menuNode' || type === 'menuTextNode') {
        const displayOptions = _.chain(options)
          .map((opt, key) => `${key + 1} - ${opt.value}`)
          .join('\n')
          .value()

        return {
          actions: [
            {
              text: `${bot.text}\n\n${displayOptions}`,
              type: 'send'
              /* interactive: {
                ...interactiveButtons(options),
                content: bot.text
              },
              message_type: 'text' */
            },
            {
              to: `check_${id}`,
              type: 'to'
            }
          ]
        }
      }
    })
    .value()
}

// CHECK
const controllers = (nodes, edges) => {
  return _.chain(nodes)
    .filter(['data.bot.hasCheck', true])
    .keyBy(({ id }) => `check_${id}`)
    .mapValues(({ data, id }) => {
      const { options, condition } = data
      const optionIds = _.chain(options)
        .map((_, key) => `'${key + 1}'`)
        .join(',')
        .value()

      const timeout =
        condition.type === 'duration'
          ? {
              to: `error_${id}`,
              minute: condition.duration
            }
          : undefined

      const any = hasAnySelect(id, nodes, edges)

      const on_true = { to: `select_${id}_1` }

      if (any.has && !any.both) on_true.to = any.anyId

      return {
        check: `message.content in (${optionIds})`,
        status: 'dont_stop',
        on_true: on_true,
        timeout: timeout, // will be editing
        on_false: { to: `again_${id}` } // will be editing
      }
    })
    .value()
}

// SELECT MENU
const selectors = (nodes, edges) => {
  const selects = _.chain(nodes)
    .filter(['data.bot.hasCheck', true])
    .mapValues(({ data, id }) => {
      const any = hasAnySelect(id, nodes, edges)

      return _.chain(data.options)
        .map((opt, key) => {
          key = key + 1
          const on_true = { to: `again_${id}` }

          const selectOpt = _.find(
            nodes,
            (node) => node.data.bot?.option?.id === opt.id
          )

          if (any.has && any.both) on_true.to = any.anyId
          if (selectOpt?.id) on_true.to = selectOpt.id

          return {
            [`select_${id}_${key}`]: {
              check: `message.content == '${key}'`,
              status: 'dont_stop',
              on_true: on_true,
              on_false:
                key !== data.options.length
                  ? {
                      to: `select_${id}_${key + 1}`
                    }
                  : undefined
            }
          }
        })
        .reduce((obj, param) => {
          const key = _.keys(param)[0]

          obj[key] = param[key]
          return obj
        }, {})
        .value()
    })
    .value()

  return _.chain(selects)
    .values()
    .reduce(function (result, value) {
      result = { ...result, ...value }

      return result
    }, {})
    .value()
}

const wrongSelections = (nodes) => {
  return _.chain(nodes)
    .filter(['data.bot.hasCheck', true])
    .keyBy(({ id }) => `again_${id}`)
    .mapValues((node) => {
      const { id, type, data } = node
      const { bot, options } = data

      if (type === 'menuNode' || type === 'menuTextNode') {
        const displayOptions = _.chain(options)
          .map((opt, key) => `${key + 1} - ${opt.value}`)
          .join('\n')
          .value()

        return {
          actions: [
            {
              text: `Lütfen tekrar seçiniz (Please choose again):\n\n${bot.text}\n\n${displayOptions}`,
              type: 'send'
            },
            {
              to: `check_${id}`,
              type: 'to'
            }
          ]
        }
      }
    })
    .value()
}

const errors = (nodes) => {
  return _.chain(nodes)
    .filter((node) => node.data.condition.type)
    .keyBy(({ id }) => `error_${id}`)
    .mapValues((node) => {
      const {
        text,
        type = undefined,
        agent,
        agentGroup
      } = node.data.condition.action

      return {
        actions: [
          {
            text: text,
            type: 'send'
          },
          {
            type: type,
            uuid: agent || agentGroup || undefined
          }
        ]
      }
    })
    .value()
}

export const dataGenerator = (schema) => {
  const { nodes, edges } = schema

  if (nodes.length === 0) return {}

  const firstBubble = _.find(nodes, ['data.isFirst', true])

  // GENERATE INIT
  const init = {
    check: 'True',
    initial: true,
    on_true: { to: firstBubble.id },
    on_false: undefined
  }

  const data = {
    init,
    ...bubbles(nodes),
    ...controllers(nodes, edges),
    ...selectors(nodes, edges),
    ...wrongSelections(nodes),
    ...errors(nodes)
  }

  /* console.log(_.chain(nodes).keyBy('id').value(), nodes)
  console.log(JSON.stringify(data, null, 2)) */

  return data
}
