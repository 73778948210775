import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { Input, Button, Checkbox } from 'antd'

// Components
import { MessageItem } from 'components'

// Styles
import {
  Wrapper,
  Container,
  Content,
  Header,
  ChatList,
  MessageBox,
  Setting
} from './Preview.styled'

// Icons
import RefreshIcon from 'assets/images/refresh-icon.svg'

// Utils
import { flowMachine } from 'utils/preview/createMachine'

const { TextArea } = Input

export const Preview = ({ flow }) => {
  const chatListContainer = useRef(null)
  const [textMessage, setTextMessage] = useState('')
  const [messages, setMessages] = useState([])
  const [showSetting, setShowSetting] = useState(true)

  const [machine, setMachine] = useState(null)
  const [machineState, setMachineState] = useState('init')
  const [timeoutId, setTimeoutId] = useState(null)
  const [flowSetting, setFlowSetting] = useState({
    initialState: true,
    isWorkingHours: true,
    customerIsInGroup: true,
    decreaseTimeout: false,
    timeoutTime: 10
  })

  useEffect(() => {
    const machineObj = flowMachine(
      flow,
      setInitialStateName,
      sendMessage,
      flowSetting
    )

    setMachine(machineObj)
  }, [flow, flowSetting])

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const setInitialStateName = (state) => {
    setMachineState(state)
  }

  const scrollToBottom = () => {
    const { scrollHeight, clientHeight } = chatListContainer.current
    const maxScrollTop = scrollHeight - clientHeight

    chatListContainer.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0
  }

  const refreshTest = () => {
    setShowSetting(true)
  }

  const onTextMessageChange = (e) => {
    setTextMessage(e.target.value)
  }

  const flowIsTyping = (status = true) => {
    if (status) {
      setMessages((prev) => [
        ...prev,
        { text: '', isSystem: true, flowTyping: true }
      ])
      return
    }
    setMessages((prev) => prev.filter((m) => !m?.flowTyping))
  }

  const sendMessage = (text, data, isSystem = true, isAction = false) => {
    const message = {
      text,
      isSystem,
      isAction,
      data
    }

    setMessages((prev) => [...prev, message])

    if (isSystem) {
      return
    }

    setTimeout(() => {
      flowIsTyping(true)
      setTimeout(() => {
        flowIsTyping(false)
        triggerMachine(message.text)
      }, 1500)
    }, 300)
  }

  const triggerMachine = (messageContent, triggerState = null) => {
    const context = {
      message: { content: messageContent }
    }

    const transmitted = machine.transition(
      !triggerState ? machineState : triggerState,
      'MESSAGE',
      context
    )
    const { actions, meta } = transmitted

    if (transmitted.value === 'break_flow') {
      setMachineState(transmitted.value)
      return
    }

    if (actions.length === 0) {
      if (
        meta &&
        meta[Object.keys(meta)[0]]?.status &&
        meta[Object.keys(meta)[0]].status !== 'dont_stop'
      ) {
        return
      }
      setMachineState(transmitted.value)
      triggerMachine(messageContent, transmitted.value)
      return
    }
    setMachineState(transmitted.value)
    actions.forEach((action) => {
      if (typeof action.exec === 'function') {
        action.exec()
      }
    })
    if (meta && meta[Object.keys(meta)[0]]?.timeout) {
      triggerTimeout(meta[Object.keys(meta)[0]].timeout, messageContent)
    }
  }

  const triggerTimeout = (timeoutTrigger, messageContent) => {
    timeoutTrigger = {
      ...timeoutTrigger,
      executed: false,
      canceled: false
    }
    setMessages((prev) => [
      ...prev,
      { text: '', isSystem: true, isAction: true, timeout: timeoutTrigger }
    ])
    const timeoutTime = flowSetting.decreaseTimeout
      ? flowSetting.timeoutTime
      : timeoutTrigger.minute * 60
    const id = setTimeout(() => {
      triggerMachine(messageContent, timeoutTrigger.to)
      clearTimeoutStep(timeoutTrigger.to)
    }, timeoutTime * 1000)

    setTimeoutId(id)
  }

  const clearTimeoutStep = (timeoutName = '') => {
    if (timeoutId != null) {
      clearTimeout(timeoutId)
      setTimeoutId(null)
    }
    setMessages((prev) => [
      ...prev.map((m) => {
        if (m?.timeout) {
          if (m.timeout.to === timeoutName && !m.timeout.canceled) {
            m.timeout.executed = true
          } else if (timeoutName === '' && !m.timeout.executed) {
            m.timeout.canceled = true
          }
        }
        return m
      })
    ])
  }

  const onSendMessage = () => {
    if (!textMessage || showSetting) return
    clearTimeoutStep()
    sendMessage(textMessage, null, false)
    setTextMessage('')
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      onSendMessage()
      e.preventDefault()
    }
  }

  const onSettingCheckboxChange = (e) => {
    setFlowSetting((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.checked
    }))
  }

  const onDecreaseTimeoutTimeChange = (e) => {
    const value = e.target.value < 10 ? 10 : e.target.value

    setFlowSetting((prevState) => ({
      ...prevState,
      timeoutTime: value
    }))
  }

  const onStartTest = () => {
    setMessages([])
    setMachineState('init')
    setTimeoutId(null)
    setShowSetting(false)
  }

  const flowFinished = machineState === 'break_flow'

  return (
    <Wrapper>
      <Container>
        <Content>
          <Header>
            <span>Chatbot Preview</span>
            <img src={RefreshIcon} alt='RefreshIcon' onClick={refreshTest} />
          </Header>
          <ChatList ref={chatListContainer}>
            {messages.map((msg, key) => (
              <MessageItem key={key} message={msg} />
            ))}
          </ChatList>
          <MessageBox>
            {!flowFinished && (
              <>
                <TextArea
                  placeholder='Text Message'
                  value={textMessage}
                  onChange={onTextMessageChange}
                  onKeyPress={handleKeyPress}
                  autoFocus
                  autosize={{ minRows: 1, maxRows: 4 }}
                  disabled={showSetting}
                />
                <Button
                  type='primary'
                  onClick={onSendMessage}
                  disabled={!textMessage || showSetting}
                >
                  Send
                </Button>
              </>
            )}
            {flowFinished && (
              <Button
                type='primary'
                onClick={refreshTest}
                disabled={showSetting}
              >
                Test is finished, Try again
              </Button>
            )}
          </MessageBox>
          {showSetting && (
            <Setting>
              <div className='title'>Setting</div>
              <div className='checkbox'>
                <Checkbox
                  checked={flowSetting.isWorkingHours}
                  onChange={onSettingCheckboxChange}
                  name='isWorkingHours'
                >
                  Is working hours
                </Checkbox>
              </div>
              <div className='checkbox'>
                <Checkbox
                  checked={flowSetting.customerIsInGroup}
                  onChange={onSettingCheckboxChange}
                  name='customerIsInGroup'
                >
                  Customer is in group
                </Checkbox>
              </div>
              <div className='checkbox'>
                <Checkbox
                  checked={flowSetting.decreaseTimeout}
                  onChange={onSettingCheckboxChange}
                  name='decreaseTimeout'
                >
                  Decrease timeout time
                </Checkbox>
              </div>
              {flowSetting.decreaseTimeout && (
                <div className='timeout'>
                  <Input
                    type='number'
                    value={flowSetting.timeoutTime}
                    onChange={onDecreaseTimeoutTimeChange}
                    addonAfter='seconds'
                  />
                </div>
              )}
              <div className='start-btn'>
                <Button type='primary' block onClick={onStartTest}>
                  Start Test
                </Button>
              </div>
            </Setting>
          )}
        </Content>
      </Container>
    </Wrapper>
  )
}

Preview.propTypes = {
  flow: PropTypes.object
}
